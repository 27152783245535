<template>
	<div>
		<div class="title">{{detail.title}}</div>
		<img :src="detail.coverPath" class="coverPic">
		<div class="content article" v-html="detail.content"></div>
	</div>
</template>

<script>
	import Api from '../../api/request.js'
	export default {
		name: 'ArticleDetail',
		data() {
			return {
				detail: {}
			}
		},
		created() {
			this.getDetail();
		},
		watch: {
			$route() {
				this.getDetail();
			}
		},
		methods: {
			getDetail() {
				this.$loading.inc();
				Api.AboutUs.articleDetail({
					articleId: this.$route.query.id
				}).then(res => {
					this.detail = res.data.data;
					this.$loading.done();
				})
			}
		}
	};
</script>

<style lang="less" scoped>
	.title {
		text-align: center;
		font-size: 20px;
		padding: 40px 0;
	}

	.coverPic {
		display: block;
		margin: 0 auto;
		min-width: 300px;
	}

	.content {
		padding: 40px 15px 150px;

		* {
			max-width: 100% !important;
			box-sizing: border-box !important;
			-webkit-box-sizing: border-box !important;
			word-wrap: break-word !important;
			margin-left: 20px;
		}
	}
</style>
<style>
	.article>ul>li {
		margin-left: 20px;
	}

	.article>ol>li {
		margin-left: 20px;
	}
</style>
